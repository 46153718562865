import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PromptDialogService } from '@x/dashboard/dialog';
import { Observable } from 'rxjs';

export interface GuardsForm {
  isDirty(): boolean;
}

@Injectable()
export class FormGuard  {
  constructor(private promptDialogs: PromptDialogService) {}

  canDeactivate(
    component: GuardsForm,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (typeof component.isDirty === 'function') {
      if (component.isDirty()) {
        return this.promptDialogs.confirm({
          title: 'Leave Form?',
          message: 'Are you sure you want to leave this form? Your changes are not saved.',
        });
      }
    } else {
      console.warn('FormGuard: Component should implement GuardsForm to use FormGuard');
    }

    return true;
  }
}
