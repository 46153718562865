import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

const URL_REGEX =
  /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

const SLUG_REGEX = new RegExp('^[0-9a-zA-Z-_]+$');

export class CommonValidator {
  static url(): ValidatorFn {
    return (control: AbstractControl) => {
      let value = control.value;

      if (value === null || String(value) === '') {
        return null;
      }

      if (!URL_REGEX.test(String(value))) {
        return { url: false };
      }

      return null;
    };
  }
  static trimmed(): ValidatorFn {
    return (control: AbstractControl) => {
      let value = control.value;

      if (
        typeof value === 'string' &&
        value.length > 0 &&
        (value[0] === ' ' || value[value.length - 1] === ' ')
      ) {
        return {
          trimmed: false,
        };
      }

      return null;
    };
  }
  static object(allowEmpty = true): ValidatorFn {
    return (control: AbstractControl) => {
      let value = control.value;

      if (value === null || String(value) === '') {
        return null;
      }

      if (typeof value !== 'object') {
        return {
          object: false,
        };
      }

      if (!allowEmpty && Object.keys(value).length === 0) {
        return {
          object: false,
        };
      }

      return null;
    };
  }
  static json(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;
      if (value === '') return null;

      const error: ValidationErrors = { jsonInvalid: true };

      try {
        JSON.parse(value);
      } catch (e) {
        return error;
      }

      return null;
    };
  }
  static email(): ValidatorFn {
    const emailRegex =
      /[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return (control: AbstractControl) => {
      let value = control.value;

      if (value === null || String(value) === '') {
        return null;
      }

      if (!emailRegex.test(String(value.toLowerCase()))) {
        return { emailInvalid: true };
      }

      return null;
    };
  }
  static phoneNumber(): ValidatorFn {
    const numberRegex = new RegExp('^(?:[0-9] ?){10,14}[0-9]$');
    return (control: AbstractControl) => {
      let value = control.value;

      if (value === null || String(value) === '') {
        return null;
      }

      if (!numberRegex.test(String(value))) {
        return { phoneNumber: true };
      }

      return null;
    };
  }
  static slug() {
    return (control: AbstractControl) => {
      let value = control.value;

      if (value === null || String(value) === '') {
        return null;
      }

      if (!SLUG_REGEX.test(String(value))) {
        return { slug: true };
      }

      return null;
    };
  }
  static valueObjectHasKey(key: any): ValidatorFn {
    return (control: AbstractControl) => {
      const { value } = control;

      const instanceOfObj = !!value && typeof value !== 'string' && key in value;

      return !instanceOfObj ? { matchRequired: true } : null;
    };
  }
}
